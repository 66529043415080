import { useMemo } from "react"
import styled from "styled-components"

import { Chip } from "src/components/Chip"
import { FilterAdd } from "src/components/Filter/FilterAdd"
import SearchFilter from "src/components/Filter/SearchFilter"
import {
  getFiltersInUrl,
  removeUrlFilters,
  setUrlParam,
} from "src/data/filters/filtersUrl"
import { IFilter } from "src/data/filters/types/filterTypes"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useUrlParam } from "src/hooks/useUrlParam"
import { useTranslate } from "src/i18n/useTranslate"
import { useRouter } from "src/router/useRouter"
import { spacing } from "src/ui/spacing"

export function FilterQueryBox({
  dropdownFilters = [],
  searchFieldFilter,
  disabled,
}: {
  dropdownFilters?: IFilter[]
  searchFieldFilter?: IFilter
  disabled?: boolean
}) {
  const { search } = useAppLocation()
  const { navigate } = useRouter()

  const { value: searchString } = useUrlParam(searchFieldFilter?.id || "")
  const { t } = useTranslate()

  const pushHistory = (search: string) => {
    const searchParams = new URLSearchParams(search)
    if (searchParams.has("offset")) {
      searchParams.set("offset", "0") // reset offset before updating url
    }
    const newSearch = searchParams.toString()
    navigate({ search: newSearch })
  }

  const dropdownFiltersActive: IFilter[] = useMemo(() => {
    const urlFilters = getFiltersInUrl(dropdownFilters, search)
    const active: IFilter[] = []
    for (const u of urlFilters) {
      for (const d of dropdownFilters) {
        if (d.id === u.id) active.push({ ...d, ...u })
      }
    }
    return active
  }, [dropdownFilters, search])

  function onRemoveFilter(filterIndex: number) {
    const filterToRemove = dropdownFiltersActive[filterIndex]
    removeUrlFilters([filterToRemove], pushHistory)
  }

  function onClearFilters() {
    removeUrlFilters(dropdownFiltersActive, pushHistory)
  }

  function onAddFilter(f: IFilter) {
    setUrlParam(f.id, f.value, pushHistory)
  }

  function onSearchChange(value: string) {
    searchFieldFilter && setUrlParam(searchFieldFilter.id, value, pushHistory)
  }

  return (
    <SFilterBox>
      <InputBox>
        {!!searchFieldFilter && (
          <SearchFilter
            label={searchFieldFilter.name(t)}
            onChange={onSearchChange}
            initialValue={searchString ? String(searchString) : ""}
            delay={500}
            minWidth={25}
            disabled={disabled}
          />
        )}
        {!!dropdownFilters && !!dropdownFilters.length && (
          <FilterAdd
            filters={dropdownFilters}
            activeFilters={dropdownFiltersActive}
            onAddFilter={onAddFilter}
            onClearFilters={onClearFilters}
            disabled={disabled}
          />
        )}
      </InputBox>

      <FilterChipBox>
        {dropdownFiltersActive.map((f, index) => {
          const label = (
            <span>
              {f.filterText || f.name(t)}
              {f.showValue && `: `}
              {f.showValue && <b>{f.value}</b>}
            </span>
          )
          return (
            <Chip
              label={label}
              onDelete={() => onRemoveFilter(index)}
              variant="outlined"
              color="primary"
              key={`${f.id}-${index}`}
            />
          )
        })}
      </FilterChipBox>
    </SFilterBox>
  )
}

const FilterChipBox = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > :not(:last-child) {
    margin-right: ${spacing.M};
  }

  & > * {
    margin-top: ${spacing.M};
  }
`

const SFilterBox = styled.div`
  padding-bottom: ${spacing.L};
`

const InputBox = styled.div`
  display: grid;
  align-items: center;
  row-gap: ${spacing.M};

  @media (min-width: 800px) {
    display: flex;
    & > :first-child {
      margin-right: 1rem;
    }
    flex-wrap: wrap;
  }
`
